import React from 'react';

import { TPill } from './model';
import { LocalizedLink } from '@/components/Link';
import Image from 'next/image';
import { StaticImage } from '@/components/StaticImage';
import classNames from 'classnames';

export const Pill = (props: TPill) => {
    const { pillType, prefix, label, selected, onClick, onChange, href, target, leftImg, rightImg } = props;
    const id = `pill-title-${prefix}-${label}`;
    function getCustomStyles() {
        return {
            ...(props?.override_border_color && { '--border-color': props.override_border_color }),
            ...(props?.override_label_color && { '--label-color': props.override_label_color }),
            ...(props?.override_background_color && { '--background-color': props.override_background_color }),
            ...(props?.override_border_color_hover && { '--border-color-hover': props.override_border_color_hover }),
            ...(props?.override_label_color_hover && { '--label-color-hover': props.override_label_color_hover }),
            ...(props?.override_background_color_hover && {
                '--background-color-hover': props.override_background_color_hover,
            }),
            ...(props?.override_border_color_focus && { '--border-color-focus': props.override_border_color_focus }),
            ...(props?.override_label_color_focus && { '--label-color-focus': props.override_label_color_focus }),
            ...(props?.override_background_color_focus && {
                '--background-color-focus': props.override_background_color_focus,
            }),
            ...(props?.override_border_color_active && { '--border-color-active': props.override_border_color_active }),
            ...(props?.override_label_color_active && { '--label-color-active': props.override_label_color_active }),
            ...(props?.override_background_color_active && {
                '--background-color-active': props.override_background_color_active,
            }),
            '--left-padding': props?.leftImg ? '3rem' : '1rem',
            '--right-padding': props?.rightImg ? '3rem' : '1rem',
        };
    }

    // Function to create the input element based on pillType
    const createInputElement = () => {
        switch (pillType) {
            case 'radio':
                return <input id={id} name={prefix} type="radio" checked={selected} value={label} onChange={onChange} />;
            case 'checkbox':
                return <input id={id} name={prefix} type="checkbox" checked={selected} value={label} onChange={onChange} />;
            case 'button':
                return (
                    <button id={id} type="button" onClick={onClick}>
                        {label}
                    </button>
                );
            case 'anchor':
                return (
                    <LocalizedLink id={id} href={href ? href : '#'} target={target}  className={classNames({ active: selected })}>
                        {label}
                    </LocalizedLink>
                );
            default:
                return <input id={id} name={prefix} type="radio" checked={selected} value={label} onChange={onChange} />;
        }
    };

    // Generate the input element
    const inputElement = createInputElement();

    const customStyles: any = getCustomStyles();
    return (<>
        <style>{`
            .ark-ui-pill {
                border: none;
                ${Object.keys(customStyles).map(prop => prop + ':' + customStyles[prop] + ';').join('')}
                background: var(--background-color, #e6e6e6);
                box-shadow: inset 0 0 0 2px var(--border-color, #656263) !important;
                filter: url('');
            }
            .ark-ui-pill:hover {
                background: var(--background-color-hover, #e6e6e6);
                box-shadow: inset 0 0 0 2px var(--border-color-hover, #656263) !important;
            }
            .ark-ui-pill:has([id^='pill-title-']:is(:active, :checked, .active)),
            .ark-ui-pill:active {
                background: var(--background-color-active, #bbbaba);
                box-shadow: inset 0 0 0 2px var(--border-color-active, #bbbaba) !important;
            }
            [data-button_borders="false"] .ark-ui-pill {
                border-color: transparent !important;
                box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5) !important;
            }
            [data-button_borders="false"] .ark-ui-pill:hover {
                box-shadow: 0 0 0 2px var(--border-color-hover, var(--color-white)) !important;
            }
            [data-button_borders="false"] .ark-ui-pill:active {
                box-shadow: 0 0 0 2px var(--border-color-active, var(--color-white)) !important;
            }
        `}</style>
        <label
            key={id}
            className={classNames('ark-ui-pill', 'text-16')}
            htmlFor={id}
            data-label={label}
            aria-label={label}
            style={{ '--left-padding': customStyles['--left-padding'], '--right-padding': customStyles['--right-padding'] } as any}
        >
            {leftImg && (
                <StaticImage width={24} height={24} loading="lazy" className="left-img" src={leftImg} alt={label} />
            )}
            {inputElement}
            {rightImg && (
                <StaticImage width={24} height={24} loading="lazy" className="right-img" src={rightImg} alt={label} />
            )}
        </label>
        </>
    );
};
