'use client';
import { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { HeadlineContext } from '../HeadlineCardsGrid';
import classNames from 'classnames';
import { PillType, THorizontalPills, toggleRadio, TPill } from '@/components/HorizontalPills/model';
import HorizontalPills from '../HorizontalPills';
import { useUnit } from 'effector-react';
import { $currentPage, $arenaDataStore, $allCategoryData } from '@/app.model';
import { capitalizeFirstLetter } from '@/shared/utils/utils';
import { Dropdown } from '../Dropdown';
import React from 'react';
import { EScreenSize } from '@/shared/utils/screensize';
import { useScreenSize } from '@/shared/utils/useScreensize';

type Props = {
    title?: string | null;
    query?: string | null;
    sortOptions?: any[];
    onSort?: Function;
    onFilter?: (selectedCategory: string) => void;
    isSearchPage?: boolean;
};

export const Headline = (props: Props) => {
    const {
        categories = [],
        setSelectedSort,
        rootCategorySlug,
        arenaCategories,
        currentFilter,
        categoryLinks,
    } = useContext(HeadlineContext);
    const currentPage = useUnit($currentPage);
    const arenaData = useUnit($arenaDataStore);
    const allCategoryPage = useUnit($allCategoryData);
    const selectRadio = useUnit(toggleRadio);
    const screenSize = useScreenSize();
    const isDesktop = screenSize !== EScreenSize.sm;

    useEffect(() => {
        if (currentFilter) {
            selectRadio({ group: horizontalGroupName, label: capitalizeFirstLetter(currentFilter) });
        }
    }, [currentFilter]);

    const title = currentPage && currentPage === 'search' ? 'Filter by category' : 'Categories';

    const onSelect = (pill: TPill) => {
        props.onFilter?.(pill.label);
    };

    const pills = categories.map((category) => {
        const label = capitalizeFirstLetter(category);
        const categoryLower = category.toLowerCase();
        const isAllCategory = categoryLower === allCategoryPage.name?.toLowerCase();
        const isActive = categoryLower === currentFilter?.toLowerCase();
        const href = isAllCategory
            ? `/${rootCategorySlug}`
            : `/${rootCategorySlug}/${
                  arenaCategories?.find((c) => c.name?.trim().toLowerCase() === categoryLower)?.slug ??
                  categoryLower.replaceAll(' ', '-')
              }`;
        const leftImg = isAllCategory
            ? `/assets/${arenaData?.layout?.themeLayout?.theme === 'light' ? 'see_all' : 'see_all_white'}.svg`
            : undefined;

        return {
            label,
            href,
            target: '_self',
            leftImg,
            rightImg: undefined,
            isActive,
        };
    });
    const horizontalGroupName = `categories-${currentPage || 'default'}`;

    const config: THorizontalPills = {
        title: title,
        pillType: categoryLinks ? PillType.Anchor : PillType.Radio,
        horizontalGroup: horizontalGroupName,
        pills: pills,
        __component: 'web-components.horizontal-generic-links',
        onSelect: onSelect,
        isSearchPage: props?.isSearchPage,
    };

    const horizontalPillsComponent = categories.length > 0 ? (
        <HorizontalPills config={config} index={0} isPillsFromPages isSearchPage={props?.isSearchPage} />
    ) : null;

    const TitleTag = currentPage === 'search' ? 'h1' : 'h2';

    return (
        <React.Fragment>
            <div className={classNames(styles.header, 'headers')}>
                <TitleTag className={styles.headline}>
                    {props.title} {props?.query && <span className={styles.query}>“{props.query}”</span>}
                </TitleTag>
                {props?.sortOptions?.length && (
                    <Dropdown
                        items={props?.sortOptions}
                        onUpdate={(e: string) => {
                            setSelectedSort?.(e);
                            props?.onSort?.(e);
                        }}
                    />
                )}
                 {!isDesktop && horizontalPillsComponent}
            </div>
            {isDesktop && horizontalPillsComponent}
        </React.Fragment>
    );
};
